import { FORM_PAGES } from '../components/Form/types';
import { THREE_DAYS } from '../components/FormRadio/helpers';
import { BranchForm, FormAnswers } from '../types';
import { Slots } from '../views/FormPages/Booking/DateAndTime';
import { generateVars } from './utils';

export type SlotType = {
  fullDate: Date;
  date: number;
  day: string;
  slots: Slots['availableSlots'][0];
};

export type Slot = {
  date?: SlotType;
  time?: string;
  dateUntil?: SlotType;
  days?: typeof THREE_DAYS;
  startDate?: SlotType;
  BusUserId?: string;
  repetition?: { type: string; repeat: number };
};

export type DiscountRuleRuleType = 'min_pets' | 'min_total' | 'min_orders';

export type DiscountRule = {
  rule: { type: DiscountRuleRuleType; amount?: number; offset?: number };
  level: 'main' | 'auto_apply';
  description: string;
};

export type Discount = {
  id: string;
  name: string;
  amount: number;
  type: 'percentage' | 'amount';
  rules: DiscountRule[];
  status: 'ACTIVE' | 'ARCHIVED';
  Vouchers: Voucher[];
  Orders: { id: string }[];
};

export type Voucher = {
  id: string;
  code: string;
  limit: number;
  status: 'ACTIVE' | 'ARCHIVED';
  period: {
    start: string;
    end: string;
  };
  Products: { id: string }[];
  AppUsers: { id: string }[];
  Discount: Discount;
  auto_apply: boolean;
};

const initialStates: {
  isProviderView: boolean;
  isFormFillView: boolean;
  currentProvider: Queries.CollarAPI_Provider | null;
  currentBranch: Queries.CollarAPI_Branch | null;
  selectedProduct: Queries.CollarAPI_Product | null;
  selectedForms: BranchForm[] | null;
  selectedCustomizations:
    | {
        fields: { [key: string]: { title: string; price: number } };
        quantity: number;
      }[];
  newAccount: Record<string, any>;
  newPet: Partial<Queries.CollarAPI_Pet>;
  newOrders: Partial<Queries.CollarAPI_StartOrderTypeInput>[] | null;
  selectedPets: Queries.CollarAPI_Pet[];
  recurrenceType: 'one_off' | 'recurring';
  selectedOrderGroupSlots: {
    slots: Slot[];
  }[];
  allPets: Record<string, unknown>[];
  breeds: Record<string, unknown>[];
  formPage: FORM_PAGES;
  navigationParams: Record<string, unknown>;
  formClass: string;
  formsAnswers: FormAnswers[];
  vouchers: Voucher[] | null;
  orderSummary: {
    discount: string;
    delivery: string;
    finalPrice: string;
    total: string;
    productsWithTotals: {
      price: number;
      customizationsPrice: number;
      basePriceWithQuantity: number;
      basePrice: number;
      quantity: number;
    }[];
  } | null;
} = {
  isProviderView: false,
  isFormFillView: false,
  currentProvider: {} as Queries.CollarAPI_Provider,
  currentBranch: {} as Queries.CollarAPI_Branch,
  navigationParams: {},
  selectedProduct: {} as Queries.CollarAPI_Product,
  selectedForms: null,
  selectedCustomizations: {} as Queries.CollarAPI_Product['customizations'][],
  newAccount: {},
  newPet: {},
  newOrders: null,
  selectedPets: [] as Queries.CollarAPI_Pet[],
  recurrenceType: 'one_off',
  selectedOrderGroupSlots: [{ slots: [] }],
  allPets: [],
  breeds: [],
  formPage: FORM_PAGES.PRODUCTS,
  formClass: 'fade',
  formsAnswers: [],
  vouchers: null,
  orderSummary: null
};

export const persistedVarsStorageKeys: (keyof typeof initialStates)[] = ['currentBranch', 'selectedProduct', 'newOrders', 'selectedOrderGroupSlots', 'recurrenceType', 'formsAnswers', 'selectedForms'];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
